.Footer-Container {
    position: relative;
    margin-top: 4rem;
}

.Footer-Container>hr {
    border: 1px solid yellow;
    width: 100%;
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
  
}

.social-links {
    display: flex;
    gap: 4rem;
}

.social-links>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f>img{
    width: 10rem;
}