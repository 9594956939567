.Reasons{
    padding:10rem;
    display:flex;
    gap:2rem;
}

.left-r{
flex: 1 1;
display: flex;
flex-direction: row;
gap:1rem;

}

.left-r>img{
    object-fit:cover;
}

.left-r>img{
    width:15rem;
    height:30rem;
}




.right-r{
    flex:1 1;
    text-transform: uppercase;
    gap:1rem;
    display:flex;
    flex-direction: column;
}

.right-r>span{
    font-weight: bold;
 
}

.right-r>div{
    color:black;
    font-size: 3rem;
    font-weight: bold;
}

.details-r{
    display:flex;
    flex-direction:column;
    gap: 1rem;
    
}

.details-r>div{
    display:flex;
    font-size: 1rem;
    gap: 1rem;
}

.details-r>div>img{
    height:2rem;
    width:2rem;
}

.partners{
    display:flex;
    gap:1rem;
}

.partners>img{
    width:2.5rem;
}

@media screen and (max-width:768px) {
    
    .Reasons{
        flex-direction:column;
        padding:3rem !important;
    }
    .left-r{
    flex-direction:column;
    
     align-items:center;
        justify-content:center;
    }

}