.header{
    display: flex;
    justify-content: space-between;
   background-color:#ececec;
}

.logo{
    width:8rem;
    height:8rem;
}

.headermenu{
    list-style: none;
    display: flex;
    gap:2.5rem;
    color:black;
    align-items:center;
}

.headermenu>li{
    font-size: 1.5rem;
}
.headermenu>li:hover{
    cursor:pointer;
    color:yellow;
    cursor:pointer;

}

@media screen and (max-width:768px) {
    .header>:nth-child(2){
        position:fixed;
        right:2rem;
        z-index: 99;
    }

    .headermenu{
        flex-direction: column;
        background-color:#ECECEC;
        padding:2rem;
    }
    
}